export const ShowMoreButton = ({ onButtonClick, buttonText }) => {
  return (
    <button
      type="button"
      className="mt-6 w-full rounded-md border border-solid border-tertiary300 bg-tertiary200 py-2 text-primary400 shadow-[0px_3px_2px_rgba(56,120,255,0.1);]"
      onClick={onButtonClick}
    >
      {buttonText}
    </button>
  );
};
